const ContactCrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Contact Us",
    slug: "/contact",
  },
]

export default ContactCrumbs
