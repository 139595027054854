import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero } from "../containers"
import { BreadCrumb, TitleV2 } from "../components"
import ContactCrumbs from "../constants/BreadCrumbs/ContactCrumbs"
import ContactSection from "../components/Sections/contactSection"

const ContactPage = ({ data }) => {
  const image = getImage(
    data.strapiContactPage.image.localFile.childImageSharp.gatsbyImageData
  )

  return (
    <>
      <Hero image={image} title={data.strapiContactPage.title} />
      <BreadCrumb crumbs={ContactCrumbs} />
      <div className="uk-margin-small-top">
        <TitleV2 title="Get in touch" />
      </div>

      <ContactSection />
    </>
  )
}

export default ContactPage

export const query = graphql`
  {
    strapiContactPage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
  }
`
