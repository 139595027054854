import React, { useState } from "react"
import useForm from "../../hooks/useForm"
import validate from "../../constants/validateContact"

const ContactSection = () => {
  const [username, setUsername] = useState("")
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    data,
    success,
    serverError,
  } = useForm(Submit, validate)

  function Submit() {
    if (success) {
      setUsername(data.name)
    } else {
      setUsername("")
    }

    // check if there were server errors and set the error below the success div uk-alert-danger
    //console.log("Submit function - success:", success)
    //console.log("Submit function - user:", user)
    //console.log("Submit function - data.name:", data.name)

    // if there is a server error return server error jsx under the form
    //console.log("Submit function - data:", data)
    //console.log("Submit function - data:", serverError)
    //console.log("Submit function: Submitted Successfully")
  }

  return (
    <div>
      <div className="uk-section uk-section-default uk-padding-remove-top">
        <div className="uk-container uk-margin-small-top">
          <div className="uk-grid-large" uk-grid="true">
            <div className="uk-flex-middle uk-width-expand@m ">
              {/* <Title title="Let's Talk" /> */}
              <h3 className="uk-heading-line uk-text-bold">
                <span>Let's Talk</span>
              </h3>
              <div className="uk-panel">
                {/* <h1 className="uk-heading-hero">Let’s talk</h1> */}
                {/* <h2>Let’s talk</h2>
                <div className="uk-divider-small"></div> */}

                <div className="uk-margin uk-width-xlarge">
                  <p>
                    If you would like to learn more about our services or if you
                    would like to book a consultation session please use the
                    contact us form here. Rest assured your personal details are
                    safe with us and will be treated in accordance with our
                    privacy policy below.
                  </p>
                  <p>
                    Email: info@yourdomain.com
                    <br />
                  </p>

                  <p className="uk-text-default uk-text-bold">Lets connect!</p>
                  <div
                    className="uk-flex-middle uk-grid-small uk-child-width-auto"
                    uk-grid="true"
                  >
                    <div>
                      <a
                        uk-tooltip="title: Twitter; pos: bottom-center"
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="twitter"
                        alt=""
                        className="uk-icon-button uk-margin-small-right"
                        uk-icon="twitter"
                      >
                        {null}
                      </a>
                    </div>
                    <div>
                      <a
                        uk-tooltip="title: Linkedin; pos: bottom-center"
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="youtube"
                        alt=""
                        className="uk-icon-button uk-margin-small-right"
                        uk-icon="linkedin"
                      >
                        {null}
                      </a>
                    </div>
                    <div>
                      <a
                        uk-tooltip="title: Facebook; pos: bottom-center"
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="facebook"
                        alt=""
                        className="uk-icon-button"
                        uk-icon="facebook"
                      >
                        {null}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-grid-item-match uk-flex-middle uk-width-expand@m">
              <div className="uk-panel">
                <div className="uk-margin ">
                  <form noValidate>
                    <div className="uk-margin ">
                      <label
                        className="uk-form-label uk-margin-top"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <input
                        name="name"
                        type="text"
                        id="form-s-text"
                        placeholder="Enter your name..."
                        value={values.name}
                        onChange={handleChange}
                        className="uk-input"
                        /* className={`${
                        errors.name && "inputError uk-input"
                      } uk-input`} */
                      />
                      {errors.name && (
                        <div className="uk-alert-danger" uk-alert="true">
                          <p className="uk-form-danger">{errors.name}</p>
                        </div>
                      )}
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="email">
                        E-Mail
                      </label>
                      <input
                        name="email"
                        type="email"
                        id="form-s-text"
                        placeholder="Enter your E-Mail address..."
                        value={values.email}
                        onChange={handleChange}
                        className="uk-input"
                        /* className={`${
                        errors.email && "inputError uk-input"
                      } uk-input`} */
                      />
                      {errors.email && (
                        <div className="uk-alert-danger" uk-alert="true">
                          <p className="uk-form-danger">{errors.email}</p>
                        </div>
                      )}
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="subject">
                        Subject
                      </label>
                      <input
                        name="subject"
                        type="text"
                        id="form-s-text"
                        placeholder="Enter your subject, max 30 characters..."
                        value={values.subject}
                        onChange={handleChange}
                        className="uk-input"
                        /* className={`${
                        errors.subject && "inputError uk-input"
                      } uk-input`} */
                      />
                      {errors.subject && (
                        <div className="uk-alert-danger" uk-alert="true">
                          <p className="uk-form-danger">{errors.subject}</p>
                        </div>
                      )}
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="message">
                        Message
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        rows="5"
                        placeholder="Enter your message, max 250 characters..."
                        value={values.message}
                        onChange={handleChange}
                        className="uk-input"
                        /* className={`${
                        errors.message && "inputError uk-textarea"
                      } uk-textarea`} */
                      />
                      {errors.message && (
                        <div className="uk-alert-danger" uk-alert="true">
                          <p className="uk-form-danger">{errors.message}</p>
                        </div>
                      )}
                    </div>
                  </form>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="uk-button uk-button-default uk-button-secondary uk-margin-small-top "
                  >
                    Send
                  </button>
                  <br />
                  {success && (
                    <div className="uk-alert-success" uk-alert="true">
                      <a href="/" className="uk-alert-close" uk-close="true">
                        {null}
                      </a>
                      <p>
                        Thank you {username}, your message has been sent
                        successfully!
                      </p>
                    </div>
                  )}
                  {serverError.error && (
                    <div className="uk-alert-danger" uk-alert="true">
                      <a href="/" className="uk-alert-close" uk-close="true">
                        {null}
                      </a>
                      <p>Sorry {username}, please try again later!</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-section uk-section-muted">
        <div className="uk-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d19859.474434226304!2d-0.6734934499999999!3d51.5235936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1635775315621!5m2!1sen!2suk"
            width="100%"
            height="415"
            frameBorder="0"
            loading="lazy"
            title="map"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default ContactSection
